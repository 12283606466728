.btn-default {
  background-color: map-get($map: $colors, $key: "blue-base");
  color: #fff;
  padding: 15px 30px;
  font-size: 0.9rem;
  border-radius: 5px;
  font-weight: bold;
  
  &:hover {
    color: inherit;
    background-color: #001d9e;
  }

  &:disabled {
    background-color: rgb(145, 145, 145);
  }
}

.btn-danger {
  background-color: map-get($map: $colors, $key: "red-base");
  color: #fff;
  padding: 15px 30px;
  font-size: 0.9rem;
  border-radius: 5px;
  font-weight: bold;

  &:disabled {
    background-color: rgb(145, 145, 145);
  }
}

.input-default {
  border: 2px solid map-get($map: $colors, $key: "border");
  border-radius: 4px;
  padding: 12px 20px;

  &::placeholder {
    font-size: 0.9rem;
    color: map-get($map: $colors, $key: "font-base");
  }
}

.input-checkbox-default {
  width: 20px;
  height: 20px;
}
