.drawer-component {
    left: 0;
    top: 0;
    background-color: #FFF;
    position: fixed;
    width: 100%;
    height: 100vh;
    transform: translateX(-100%);
    transition: .3s ease;
    overflow-x: hidden;
    overflow-y: auto;

    &__close {
        position: absolute;
        right: 15px;
        top: 10px;
    }

    &__title {
        font-size: 1.4rem;
        border-bottom: 2px solid rgba(51, 51, 51, 0.155);
    }
}

.drawer-component.open {
    transform: translateX(0%);
}