// Background class
textarea:focus,
input:focus,
select:focus,
button:focus {
  box-shadow: 0 0 0 0 !important;
  outline: 0;
}

// Class Colors
.color-black {
  color: #000;
}

.color-grey {
  color: rgb(180, 180, 180);
}

.color-white {
  color: #fff;
}

.color-dark-blue {
  color: #203391;
}

.color-grey-menu {
  color: map-get($map: $colors, $key: "font-base");
}

.bg-blue-light {
  background-color: map-get($map: $bg-colors, $key: "bg-blue-light");
}

.bg-color-grey {
  background-color: #f8faff;
}

.bg-color-blue {
  background-color: map-get($map: $colors, $key: "blue-base");
}

.bg-color-white {
  background-color: #fff;
}

// Borders
.border-blue {
  border: 1px solid map-get($map: $colors, $key: "blue-base");
}

.border-grey {
  border: 1px solid map-get($map: $colors, $key: "border");
}

.border-2-blue {
  border: 2px solid map-get($map: $colors, $key: "blue-base");
}

// Sizes
.size-05 {
  font-size: 0.5em;
}
.size-06 {
  font-size: 0.6em;
}
.size-07 {
  font-size: 0.7em;
}
.size-08 {
  font-size: 0.8em;
}
.size-09 {
  font-size: 0.9em;
}
.size-11 {
  font-size: 1.1em;
}
.size-12 {
  font-size: 1.2em;
}
.size-13 {
  font-size: 1.3em;
}
.size-14 {
  font-size: 1.4em;
}
.size-15 {
  font-size: 1.5em;
}

// Rounded
.rounded-05 {
  border-radius: 5px;
}

.rounded-06 {
  border-radius: 6px;
}

.rounded-07 {
  border-radius: 7px;
}

.rounded-08 {
  border-radius: 8px;
}

.rounded-09 {
  border-radius: 9px;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-15 {
  border-radius: 15px;
}
.rounded-20 {
  border-radius: 20px;
}
.rounded-25 {
  border-radius: 25px;
}
.rounded-30 {
  border-radius: 30px;
}
.rounded-35 {
  border-radius: 35px;
}
.rounded-40 {
  border-radius: 40px;
}

// Images

.img-default {
  max-width: 100%;
}

// Transformations

.bold {
  font-weight: bold;
}

.bold-300 {
  font-weight: 300;
}
// width - height

.max-h100-vh {
  max-height: 100vh;
}

.menu-selected {
  background-color: map-get($map: $colors, $key: "blue-base");
  color: #fff;
  border-radius: 5px;
}

.to-upper {
  text-transform: uppercase;
}

.to-capitalize {
  text-transform: capitalize;
}

.to-lower {
  text-transform: lowercase;
}

.copec-tab {
  border-radius: 5px 5px 0 0;
}

.validation-error {
  color: red;
  font-weight: bold;
  width: 100%;
  text-align: right;
  font-size: 0.8em;
  padding: 2px 10px;
}

.transparent {
  border: none;
  background-color: transparent;
}

.capitalize {
  text-transform: capitalize;
}
