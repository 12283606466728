@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "rsuite/dist/rsuite.min.css";
@import "./variable";
@import "./html_components";
@import "./helper_classes";
@import "./bootstrap.component.scss";

@font-face {
  font-family: Norms;
  src: url("../assets/fonts/tt_norms_pro_regular.otf");
}

@font-face {
  font-family: Norms;
  src: url("../assets/fonts/tt_normas_pro_bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: Norms;
  src: url("../assets/fonts/tt_norms_pro_medium.otf");
  font-weight: 300;
}

body,
html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: map-get($map: $colors, $key: "bg");
  font-family: "Norms", sans-serif;
}
#root {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.image-example {
  max-width: 200px;
}

.row {
  .dashed {
    border: 1px dashed map-get($map: $colors, $key: "border");
  }
  .dashed:nth-child(even) {
    border-left: hidden;
  }
}

a {
  text-decoration: none;
}

.rs-btn-toggle.rs-btn-toggle-checked {
  background-color: map-get($map: $colors, $key: "blue-base") !important;
}

.rs-table-cell {
  background-color: transparent;
}

.striped:nth-child(even) {
  background-color: #f0f3f5;
}

.rs-uploader-trigger-btn {
  padding: 15px 20px !important;
  width: 100%;
  background-color: #d9e1e7 !important;
  color: map-get($map: $colors, $key: "font-base") !important;
  font-weight: bold !important;
}

.rs-picker.rs-picker-input.rs-picker-default.rs-picker-toggle-wrapper.rs-picker-cleanable.rs-picker-tag {
  padding: 8px 0px !important;
  border: 2px solid map-get($map: $colors, $key: "border") !important;
  width: 100%;
}

.rs-copec.rs-copec-select.rs-copec-default.rs-copec-toggle-wrapper.rs-copec-cleanable
  > .rs-picker-toggle.rs-btn.rs-btn-default {
  padding: 14px 9px !important;
  border: 2px solid map-get($map: $colors, $key: "border") !important;
  width: 100%;
  color: #000;
  font-weight: bold;
  background-color: #fff;

  svg,
  span {
    top: 17px;
  }
}

.rs-copec-none {
  padding: 10px;
  color: map-get($map: $colors, $key: "grey-base");
}

.rs-copec.rs-copec-date.rs-copec-default.rs-copec-toggle-wrapper.rs-copec-cleanable {
  .rs-picker-toggle.rs-btn.rs-btn-default {
    padding: 15px;
    background-color: transparent;
    border: 2px solid #d9e1e7 !important;

    .rs-picker-toggle-clean.rs-btn-close {
      top: 16px;
      right: 40px;
    }
    svg {
      top: 15px;
    }
  }
}

.rs-calendar-table-cell-content {
  position: relative;
}

.rs-calendar-header-title {
  text-transform: capitalize;
}

.rs-copec-disabled .rs-picker-toggle-value {
  color: #b3b3b3 !important;
  font-weight: normal !important;
}

.rs-btn-toggle.rs-btn-toggle-disabled.rs-btn-toggle-checked {
  background-color: #d9e1e7 !important;
  color: #b3b3b3 !important;
}

.rs-picker-disabled .rs-tag {
  opacity: .3;
}

.error-validations {
  width: 100%;
  margin-top: 0.25rem;
  color: #dc3545;
  display: none;

  &.error-enabled {
    display: block;
  }
}

.item-canceled {
  text-decoration: line-through;
  color: #00000050;
}
