.pagination-component {
    display: flex;
    justify-content: center;
    font-weight: 300;
    color: #666;
    
    &__btn {
        padding: 3px 10px;
        border: 2px solid #f5f5f5;
        background-color: #f5f5f5;

        &:first-child {
            border-radius: 5px 0px 0px 5px;
        }

        &:last-child {
            border-radius: 0px 5px 5px 0px;
        }

        &:disabled {
            background-color: #f7f9ff;
            color: rgb(160, 160, 160);
        }
    }

    &__center {
        padding: 3px 10px;
        border-top: 2px solid #f5f5f5;
        border-bottom: 2px solid #f5f5f5;
        min-width: 35px;
        cursor: pointer;
    }

    &__page_input {
        padding: 10px;
        width: 70px;
        height: 50px;
        border-top: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
        border-left: 1px solid #e3e3e3;
        text-align: center;
    }

    &__page_btn {
        width: 50px;
        padding: 10px;
        height: 50px;
        border-top: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
        border-right: 1px solid #e3e3e3;
    }
}
